
body {
  margin: 0;
	font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:18px;
  overflow-x: hidden;
  line-height: 1.4em;
}
h1,h2,h3,h4,h5,h6{
	font-family: "Montserrat", sans-serif;
  font-weight: 700;
  line-height: 1.3em;
}
html {
	overflow-x: hidden;
}
body {
	overflow-x: hidden;
}
button{
	font-family: "Montserrat", sans-serif;
}
p{
	font-family: "Montserrat", sans-serif;
  font-size:18px;
  margin-bottom: 20px;
  line-height: 1.4em;
  margin-top: 0 !important;
}
h1 {
  font-size: 40px; /* 2.5rem */
  font-weight: 600; /* Adjust as needed */
  margin-bottom: 15px; /* Margin bottom */
  margin-top: 0 !important;
  line-height: 1.3em;
}
h2 {
  font-size: 32px; /* 2rem */
  font-weight: 600;
  margin-bottom: 15px;
  line-height: 1.3em;
  margin-top: 0 !important;
}
button {
	padding: 12px 22px;
	font-size: 16px;
	text-transform: uppercase;
	border-radius: 3px;
	border: 0;
	cursor: pointer;
	line-height: 1.3;
}
.banleft button:hover {
	background: #3ab8eb;
	color: #fff;
	border-color: transparent !important;
}
*{
  outline: none !important;
}

h3 {
  font-size: 28px; /* 1.75rem */
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0 !important;
}
h4 {
  font-size: 24px; /* 1.5rem */
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0 !important;
}

h5 {
  font-size: 20px; /* 1.25rem */
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0 !important;
}

h6 {
  font-size: 16px; /* 1rem */
  font-weight: 600;
  margin-bottom: 15px;
  margin-top: 0 !important;
}
code {
	font-family: "Montserrat", sans-serif;
}
img {
	max-width: 100%;
	display: block;
	height: auto;
}