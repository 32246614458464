.footer.footerct {
  background: #000;
  padding: 50px 0;
  position: relative;
}
.forow {
  display: flex;
  gap: 10px;
}
.foologo {
  width: 20%;
}
.foomenuu {
  width: 60%;
}
.foocopy {
  width: 20%;
}
.foonavbar {
  padding: 0;
  margin: 0 !important;
}
.foonavbar li {
  display: inline-block;
  padding: 5px 10px !important;
  text-align: center;
}
.foonavbar li a {
  text-decoration: unset !important;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}
.foomenuu {
  text-align: center;
}
.forow {
  display: flex;
  gap: 10px;
  align-items: center;
}
.foomenuu li a:hover {
  color: #3ab8eb;
}
.foocopy p {
  color: #fff;
  font-size: 15px;
  text-align: right;
  font-weight: 500;
}
.socialicon {
  text-align: right;
}
.socialicon i {
  color: #fff;
  font-size: 22px;
}
.socialicon a {
  margin-right: 15px !important;
}
.socialicon a:last-child {
  margin-right: 0px !important;
}
.socialicon i:hover {
  color: #3ab8eb !important;
}
.foocopy p {
  color: #fff;
  font-size: 15px;
  text-align: right;
  font-weight: 500;
  margin-bottom: 5px !important;
  padding-top: 28px;
}
.scroll-to-top {
  position: fixed;
  bottom: 10px;
  right: 20px;
  background-color: #49c0b6;
  color: white;
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

.scroll-to-top:hover {
  background-color: #49c0b6;
}

.scroll-to-top:focus {
  outline: none;
}

html {
  scroll-behavior: smooth;
}
.footer.footerct {
  overflow: hidden;
}
.foocol {
  position: relative;
  z-index: 9;
}
@media (min-width: 1300px) {
  .footer.footerct::before {
    content: "";
    background: url(/src/img/fl.png);
    width: 265px;
    height: 660px;
    position: absolute;
    left: 0;
    top: -414px;
    z-index: 0;
  }
  .footer.footerct::after {
    content: "";
    background: url(/src/img/fr.png);
    width: 405px;
    height: 484px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}
@media (max-width: 800px) {
  .forow div {
    width: 100% !important;
  }
  .forow {
    flex-direction: column !important;
  }
  .footer.footerct {
    padding: 25px 0 !important;
  }
  .foologo {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  .foonavbar li {
    padding: 5px 8px !important;
    text-align: center;
  }
  .foonavbar li a {
    text-decoration: unset !important;
    color: #fff;
    font-size: 16px !important;
    font-weight: 500;
  }
  .foocopy p {
    text-align: center !important;
    padding-top: 10px !important;
    margin-bottom: 12px !important;
  }
  .socialicon {
    text-align: center !important;
  }
}
@media (min-width: 801px) and (max-width: 1100px) {
  .foologo {
    width: 25%;
  }
  .footer.footerct {
    padding: 40px 0;
  }
  .foocopy {
    width: 25%;
  }
  .foonavbar li a {
    font-size: 17px !important;
  }
  .foomenuu {
    width: 50%;
  }
}
