.pricb1.priceb2 {
    background: #f7f7f7;
  }
  
  .priceboxes01 {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .pricb1 {
    flex: 1 1;
    background: #f7f7f7;
    padding: 35px 15px !important;
    border-radius: 3px;
  }
  
  .pricb1 h3 {
    text-align: center;
    font-size: 50px;
    color: #000;
    margin-bottom: 10px;
  }
  
  .pde {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #0c8059;
  }
  
  .pr {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    color: #575756;
  }
  
  .btn3 {
    background: #3ab8eb;
    font-size: 20px;
    padding: 10px 40px;
    font-weight: 700;
    color: #fff;
  }
  
  .btn3:hover {
    background: #3ab8ebc4;
  }
  
  .pricb1 {
    text-align: center;
  }
  
  .list01 {
    list-style: none;
    text-align: left;
    padding: 0;
    margin-top: 20px;
  }
  
  .list01 {
    max-width: 310px !important;
    margin-left: auto;
    margin-right: auto;
    text-align: center !important;
  }
  
  .list01 li {
    font-size: 19px;
    color: #575756;
    font-weight: 500;
    position: relative;
    padding-left: 0px;
    margin-bottom: 13px;
  }
  #pricing {
	padding: 0 20px 40px 20px;
}
  .list01 li::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
    height: 27px;
    background-image: url("/src/img/limg.png");
    background-repeat: no-repeat;
    background-size: 20px !important;
    top: 15px;
  }
  .list01 li img {
	max-width: 20px !important;
	display: inline;
	margin-right: 10px;
	position: relative;
	top: 2px;
}
  .pr {
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    color: #575756;
  }
  
  .pde {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    color: #0c8059;
  }
#pricing .container {
	background: #fff;
	border-radius: 20px;
	margin-top: -75px;
	padding: 20px;
	box-shadow: 0 0 34px rgba(0,0,0,.12);
}
@media(max-width:767px){
  .requestdemobanner {
    padding: 200px 0px 120px 0 !important;
  }
  .reqrow01 h1 {
    font-size: 40px !important;
  }
  .reqrow01 h2 {
    font-size: 18px !important;
  }
  .reqrow01 p {
    font-size: 17px !important;
  }
  .pricb1 h3 {
    text-align: center;
    font-size: 28px !important;
  }
  .pr {
    text-align: center;
    font-size: 20px !important;
  }
  .btn3 {
    background: #3ab8eb;
    font-size: 16px;
  }
  .pr.pde0 {
    display: none;
  }
  .pricb1 {
    padding: 15px !important;
  }
  .list01 li {
    font-size: 17px;
  }
  .list01 li img {
    max-width: 17px !important;
  }
}
@media(min-width:768px) and (max-width:1024px){
  .reqrow01 h1 {
    font-size: 50px !important;
  }
  .requestdemobanner {
    padding: 200px 0px 120px 0 !important;
  }
  .reqrow01 h2 {
    font-size: 20px !important;
  }
  .reqrow01 p {
    font-size: 18px !important;
  }
  .list01 li {
    font-size: 17px;
  }
  .list01 li img {
    max-width: 17px !important;
  }
  .btn3 {
    background: #3ab8eb;
    font-size: 17px;
  }
  .pricb1 h3 {
    text-align: center;
    font-size: 35px !important;
  }
  .pr {
    text-align: center;
    font-size: 22px !important;
  }
}
@media(min-width:1025px) and (max-width:1200px){
  .requestdemobanner h1 {
    font-size: 60px !important;
  }
  .requestdemobanner {
    padding: 200px 0px 120px 0 !important;
  }
  .pricb1 h3 {
    text-align: center;
    font-size: 40px;
  }
  .btn3 {
    background: #3ab8eb;
    font-size: 18px;
  }
}