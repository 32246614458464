.aboutdemobanner {
	background: url(/src/img/about-ban.jpg);
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 250px 0px 120px 0
}
.customlist01 {
    list-style: none; 
    padding-left: 0px; 
}

.customlist01 li {
    background-image: url('/src/img/limg.png'); 
    background-size: 16px 16px; 
    background-repeat: no-repeat;
    background-position: 0 5px; 
    padding-left: 25px; 
}
.customlist01 li {
	font-size: 20px;
	font-weight: 500;
	margin-top: 10px;
	margin-bottom: 10px;
}
.customlist01 li span {
	font-weight: 700;
	color: #0068b3;
}
.abourow02 {
	padding-bottom: 40px;
	padding-left: 20px;
	padding-right: 20px;
}
.abourow02 .container {
	background: #fff;
	border-radius: 20px;
	margin-top: -75px;
	padding: 20px;
	box-shadow: 0 0 34px rgba(0,0,0,.12);
}
.mapimg img {
	float: right;
	margin: 25px 0;
}
.timeimg img {
	margin: 0 auto !important;
	width: 100%;
}
@media(max-width:767px){
	.aboutdemobanner {
		padding: 200px 0px 120px 0 !important;
	}
	.aboutdemobanner h1 {
		font-size: 35px !important;
	}
	.customlist01 li {
		font-size: 16px;
	}
}
@media(min-width:768px) and (max-width:1024px){
	.reqrow01 h1 {
	  font-size: 50px !important;
	}
	.aboutdemobanner {
		padding: 200px 0px 120px 0 !important;
	  }
	.customlist01 li {
		font-size: 17px;
	}
}
@media(min-width:1025px) and (max-width:1200px){
	.reqrow01 h1 {
	  font-size: 50px !important;
	}
	.aboutdemobanner {
		padding: 200px 0px 120px 0 !important;
	  }
	.customlist01 li {
		font-size: 18px;
	}
}