.requestdemobanner {
	background: url(/src/img/inner-ban.jpg);
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	padding: 250px 0px 120px 0
}
.reqrow01 {
	text-align: center;
}
.reqrow01 h1 {
	color: #fff;
	font-size: 74px;
	font-family: "Teko" !important;
}
.reqrow01 h2 {
	font-size: 20px;
	color: #fff;
	font-weight: 500;
}
.reqrow01 p {
	color: #fff;
	font-size: 21px;
	font-weight: 600;
	text-decoration: underline;
}
.formrow02 {
	padding-bottom: 40px;
}