.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.3s, box-shadow 0.3s;
}
.menu-items a {
  font-family: "Montserrat", sans-serif;
  font-size: 15px !important;
  font-weight: 500;
}
/* Transparent header for the home/index page */
.header.index {
  background-color: transparent;
  box-shadow: none;
}
/* Non-transparent header for other pages */
.header.not-index {
  background-color: #000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.header.scrolled.not-index {
  position: fixed;
}
.header.scrolled .close-menu {
  top: -53px;
}
.header.scrolled.index {
	background-color: #000;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header.index {
  position: fixed;
}

/* .header.scrolled {
  background-color: #000;
} */
.header.scrolled {
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 10px 0px;
}
.menu-items li:last-child a:hover {
	background: #fff;
	color: #3ab8eb !important;
}
.header-container {
  max-width: 1410px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.scrolled  .logo-image {
	max-width: 120px !important;
}
.header-menu {
  display: flex;
}

.menu-items {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.menu-items li {
  margin-left: 40px;
}

.menu-items a {
  color: #fff;
  text-transform: uppercase;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.menu-items a:hover {
  color: #3ab8eb;
}

.menu-items li:last-child a {
  background: #3ab8eb;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  padding: 11px 20px;
  border-radius: 2px;
}

.hamburger {
  display: none;
  background: transparent;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0;
}

.hamburger .bar {
  display: block;
  background-color: #fff;
  height: 2px;
  width: 25px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

.hamburger:hover .bar {
  background-color: #3ab8eb;
}

.close-icon {
  display: none;
  color: #fff;
  font-size: 24px;
}

.header-menu.open {
  display: block;
  position: absolute;
  top: 60px;
  right: 20px;
  background: #000;
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1001;
}

.header-menu.open .menu-items {
  display: block;
  margin: 0;
  padding: 0;
}

.header-menu.open .menu-items li {
  margin-left: 0;
  border-bottom: 1px solid #fff;
}

.header-menu.open .menu-items li:last-child {
  border-bottom: none;
}

.header-menu.open .menu-items a {
  display: block;
  padding: 15px 20px;
  color: #fff;
  font-size: 18px;
}
.close-menu {
	background: #0000;
	border: none;
	color: #fff;
	cursor: pointer;
	display: none;
	font-size: 48.4px;
	height: 30px;
	line-height: 0 !important;
	padding: 0;
	position: absolute;
	right: 20px;
	top: -70px !important;
}
.close-menu {
  margin-top: 0 !important;
}

@media (max-width: 1024px) {
  .header-menu {
    display: none;
  }
  .logo-image {
    max-width: 120px !important;
  }
  .hamburger {
    margin-top: 0 !important;
  }
  .header-menu.open {
    display: block;
    max-width: 100%;
    right: 0;
    top: 100%;
  }

  .hamburger {
    display: block;
  }

  .close-icon {
    display: none;
  }
  .header.scrolled .close-menu {
    top: -70px !important;
  }
  .close-menu {
    display: block;
  }
  .header-menu.open .menu-items a {
    display: block;
    padding: 15px 20px;
    color: #fff;
    font-size: 15px;
  }
  .menu-items li:last-child a {
    display: inline-block !important;
    margin: 10px;
    padding: 10px 24px !important;
    color: #fff !important;
  }
  .menu-items li:not(:last-child) a:hover {
    color: #3ab8eb !important;
  }
  .header-menu.open button:hover {
    color: #fff !important;
    background: unset !important;
  }
  .hamburger:hover {
    background: unset !important;
    color: #fff !important;
  }
  .hamburger:hover .bar {
    background-color: #fff;
  }
}

@media (max-width: 767px) {
}
