.bannerrows {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  align-items: center;
}
.newbanner {
  position: relative;
}
.blocktext:first-child {
  margin-left: 0;
}
.newbanner::after {
  content: "";
  background: url(/src/img/banafter1.png);
  width: 750px;
  height: 688px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
}
.newbanner::before {
  content: "";
  background: url(/src/img/banbefore.png);
  width: 596px;
  height: 513px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-repeat: no-repeat;
}
.homerow1 h1 {
  color: #000;
  font-family: "Teko", sans-serif;
  font-size: 48px;
  text-align: left;
  font-weight: 600;
}
.imgagbox {
  margin-top: -100px;
}
.homerow1 {
  text-align: left;
  max-width: 994px;
  margin: auto;
}
.blocktext {
  position: relative;
  width: 120px !important;
  height: 60px !important;
  background-color: #0092db;
  margin: 0 20px;
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 15px;
  line-height: 1.3em !important;
  max-width: 120px !important;
  display: inline;
}

.blocktext::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -29px;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid #0092db;
}
.homerow1 p {
  font-size: 21px;
  font-weight: 500;
  color: #000;
  margin-top: 35px !important;
}
.newbanner {
  padding-top: 200px;
  padding-bottom: 150px;
}
.homerow1 button {
  background: #0092db !important;
  border: 1px solid #0092db;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}
.homerow1 button:hover {
  background: #014fa1 !important;
  border: 1px solid #014fa1;
  color: #fff;
}
.container1 {
  max-width: 1800px;
  margin: 0 auto;
  padding: 0 20px;
}
.container {
  max-width: 1410px;
  margin: 0 auto;
  padding: 0px 20px;
}
.homerow3 {
  padding: 40px 0px;
}
.homerow3 h2 {
  text-align: center;
  font-size: 52px;
  color: #333333;
  font-weight: 600;
}
.homerowinner3 {
  max-width: 1309px;
  margin: auto;
}
.imgagbox1 {
  margin-top: 30px;
}
.homein3 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  background: url(/src/img/nbg.png);
  background-size: cover;
  background-position: left bottom;
  border-radius: 10px;
  align-items: center;
  padding: 15px;
}
.righimg img {
  margin-right: 0;
  margin-left: auto;
}
.homein3 h2 {
  font-family: "Montserrat", sans-serif;
  font-size: 42px;
  color: #fff;
  font-weight: 600;
  padding-left: 20px;
}
.homerowinner4 h2 {
  text-align: center;
  font-size: 34px;
  color: #333;
  font-weight: 600;
}
.homerow4 {
  padding-bottom: 50px;
}
.fourgridbox1 .gridimg {
  background: #169fdb;
  padding: 12px;
}
.grdc {
  background: #ccecfa;
  padding: 12px;
  align-content: center;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}
.fourgridbox {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  margin-top: 30px !important;
}
.fbox {
  display: flex;
}
.grdc h3 {
  text-align: left;
  font-size: 22px;
  color: #333;
  font-weight: 600;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  padding-left: 15px;
}
.gridimg {
  width: 30%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  align-content: center;
}
.fourgridbox2 .gridimg {
  background: #363636;
  align-content: center;
  padding: 12px;
}
.fourgridbox2 .gridtext.grdc {
  background: #d6d6d6;
}
.fourgridbox3 .gridimg {
  background: #ffd740;
  align-content: center;
  padding: 12px;
}
.fourgridbox3 .gridtext.grdc {
  background: #ffeeb2;
}
.fourgridbox4 .gridimg {
  background: #70ad47;
  align-content: center;
  padding: 12px;
}
.fourgridbox4 .gridtext.grdc {
  background: #c4e0b2;
}
.homerow5 {
  padding: 50px 0;
  background: #fcfcfc;
  position: relative;
}
.tworowbox {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-top: 30px;
  flex-wrap: nowrap;
}
.homerowinner5 h2 {
  text-align: center;
  color: #333333;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 40px !important;
}
.imgcontextbox h3 {
  font-size: 50px;
  font-weight: 700;
  color: #333333;
}
.tworowbox div {
  flex: 1;
}
.imgcontextbox ul {
  padding-left: 20px;
}
.imgcontextbox li {
  color: #333333;
  font-size: 21px;
  font-weight: 500;
  padding: 5px 0;
}
.imgcontextbox li::marker {
  color: #3ab8eb;
}
.homerowinner5 {
  position: relative;
  z-index: 9;
}
.homerow6 {
  background: url(/src/img/phbg.png);
  padding: 50px 0;
  background-size: cover;
  background-position: center bottom;
}
.threeimg {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}
.howhead h2 {
  font-size: 45px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.homerow7 {
  padding: 50px 0;
}
.homerow7 h2 {
  font-size: 40px;
  color: #333333;
  font-weight: 600;
  text-align: center;
  margin-bottom: 25px;
}
.gallerybox001 {
  margin-top: 20px;
}
@media (min-width: 1300px) {
  .homerow5::before {
    content: "";
    background: url(/src/img/left1.png);
    width: 166px;
    height: 934px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .homerow5::after {
    content: "";
    background: url(/src/img/left2.png);
    width: 137px;
    height: 630px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
}


@media (max-width: 767px) {
  .homerow1 h1 {
    font-size: 40px !important;
    line-height: 1.2em !important;
  }
  .newbanner::before {
    content: "";
    background: url(/src/img/banbefore1.png) !important;
  }
  .homein3 h2 {
    font-size: 25px;
    padding-left: unset;
  }
  .homein3 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .newbanner::after {
    background-size: 0 !important;
    background-repeat: no-repeat !important;
  }
  .righimg img {
    margin-right: auto;
    margin-left: auto;
  }
  .homein3 h2 {
    text-align: center;
    margin-bottom: 0;
  }
  .newbanner {
    padding-top: 150px;
    padding-bottom: 50px;
  }
  .bannerrows {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    align-items: center;
  }
  .homerow1 {
    text-align: center;
  }
  .homerowinner4 h2 {
    text-align: center;
    font-size: 26px !important;
  }
  .homerow1 h1 {
    text-align: center;
  }
  .grdc h3 {
    text-align: left;
    font-size: 20px !important;
  }
  .homerowinner5 h2 {
    text-align: center;
    color: #333333;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 15px !important;
  }
  .tworowbox {
    flex-direction: column;
  }
  .imgcontextbox h3 {
    font-size: 26px;
  }
  .imgcontextbox li {
    color: #333333;
    font-size: 16px;
  }
  .tworowbox.mobres {
    flex-direction: column-reverse;
    margin-top: 20px !important;
  }
  .homerow5 {
    padding: 30px 0 !important;
  }
  .homerow6 {
    padding: 30px 0;
  }
  .howhead h2 {
    font-size: 28px;
    text-align: center;
  }
  .homerow7 {
    padding: 30px 0;
  }
  .homerow7 h2 {
    font-size: 28px !important;
  }
  .blocktext {
    position: relative;
    height: 25px !important;
    background-color: #0092db;
    margin: 15px 20px;
    font-size: 17px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px 15px;
    line-height: 1.3em !important;
    max-width: 100px !important;
    text-align: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .homerbanner {
    padding: 180px 0px 100px 00px;
  }
  .imgagbox {
    margin-top: -40px;
  }
  .homerow1 p {
    font-size: 17px !important;
  }
  .homerow1 button {
    font-size: 16px !important;
  }
  .homerow3 h2 {
    font-size: 24px !important;
  }
  .fourgridbox {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .gim img {
    text-align: center !important;
    margin: auto !important;
    height: 75px;
    object-fit: contain;
  }
}
@media (max-width: 360px) {
  .blocktext {
    position: relative;
    height: 25px !important;
    background-color: #0092db;
    margin: 15px 20px;
    font-size: 20px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    padding: 5px 15px;
    line-height: 1.3em !important;
    max-width: 100px !important;
    display: inline-block;
  }
}
@media (min-width: 361px) and (max-width: 476px) {
  .homerow1 h2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    width: 100%;
    gap: 15px;
    grid-row-gap: 25px;
  }

  .blocktext {
    margin: 5px 0;
  }
  .blocktext:last-child {
    grid-column: 1 / -1;
    text-align: center !important;
    margin: 0 auto;
  }
}
@media (min-width: 477px) and (max-width: 767px) {
  .blocktext {
    margin: 20px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .homerbanner {
    padding: 200px 0px 150px 00px;
  }
  .newbanner::before {
    content: "";
    background: url(/src/img/banbefore1.png) !important;
  }
  .newbanner::after {
    right: -250px !important;
    background-size: 500px !important;
    opacity: 0.6;
  }
  .newbanner {
    padding-top: 150px;
    padding-bottom: 100px;
  }
  .homerow1 h1 {
    font-size: 40px;
  }
  .homerow3 h2 {
    text-align: center;
    font-size: 30px;
  }
  .fourgridbox {
    grid-template-columns: repeat(2, 1fr);
  }
  .grdc h3 {
    text-align: left;
    font-size: 20px;
  }
  .imgcontextbox h3 {
    font-size: 30px;
  }
  .imgcontextbox li {
    font-size: 17px;
  }
  .homein3 h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 30px;
    padding-left: 10px;
  }
  .blocktext {
    font-size: 19px;
  }
  .homerow1 p {
    font-size: 19px;
  }
  .howhead h2 {
    font-size: 30px;
  }
  .homerow7 h2 {
    font-size: 30px;
  }
  .homerowinner5 h2 {
    font-size: 30px !important;
  }
  .homerowinner4 h2 {
    text-align: center;
    font-size: 30px !important;
  }
  .homerow1 button {
    font-size: 16px;
  }
}
@media (min-width: 1025px) and (max-width: 1200px) {
  .homerow3 h2 {
    font-size: 35px;
  }
  .grdc h3 {
    text-align: left;
    font-size: 20px;
  }
  .homein3 h2 {
    font-size: 35px;
  }
  .gim img {
    max-width: 40px !important;
  }
  .imgcontextbox h3 {
    font-size: 35px;
  }
  .imgcontextbox li {
    font-size: 18px;
  }
  .howhead h2 {
    font-size: 35px;
  }
  .homerow7 h2 {
    font-size: 35px;
  }
}
@media (max-width: 480px) {
  .threeimg {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .bannerrows {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    align-items: center;
  }
  .homerow1 {
    text-align: center;
  }
  .homerow1 h1 {
    font-size: 40px;
    text-align: center;
  }
}
